/*jshint bitwise: false*/
/*jshint sub:true*/

function convertHex(hex,opacity){
    hex = hex.replace('#','');
    r = parseInt(hex.substring(0,2), 16);
    g = parseInt(hex.substring(2,4), 16);
    b = parseInt(hex.substring(4,6), 16);

    result = [r, g, b];
    return result;
}

var colorStops = [];
colorStops.push({percentage: 0,		color: convertHex('#e41d38')});
colorStops.push({percentage: 25,	color: convertHex('#f7941d')});
colorStops.push({percentage: 50,	color: convertHex('#f1cc16')});
colorStops.push({percentage: 75,	color: convertHex('#8dc63f')});
colorStops.push({percentage: 100, color: convertHex('#52b848')});

var getStepColor = function (colorA, colorB, value) {
    return colorA.map(function (color, i) {
        return (color + value * (colorB[i] - color)) & 255;
    });
};

var rgbToHex = function(r,g,b){
    return 'rgb(' + r + ',' + g + ',' + b + ')'; 
};

function toggle_year_handlers() {
	jQuery('.gradient-score-chart.multi .icon.previous').hide();
	
	jQuery('.score-chart-legend input').on('click', function (e) {
		if (e.currentTarget.checked) {
			jQuery(e.currentTarget).closest('.linear-chart-container').find('.gradient-score-chart.multi .icon.' + e.currentTarget.value).show();
		}		
		else {
			//jQuery('.gradient-score-chart.multi .icon.' + e.currentTarget.value).hide();
			jQuery(e.currentTarget).closest('.linear-chart-container').find('.gradient-score-chart.multi .icon.' + e.currentTarget.value).hide();
		}
	});	
}


function gradient_backgrounds() {
	jQuery('[data-gradient-step]').each(function (index) {
		var percentage = jQuery(this).data('gradient-step');
		
		var i;
		for (i = 0; i < colorStops.length; i++) {
			if (colorStops[i].percentage > percentage) {
				break;
			}
		}
    
		var weight = 1;
		var lowerIndex = i - 1;
    var upperIndex = i === colorStops.length ? lowerIndex : lowerIndex + 1;
		
		var upper = colorStops[upperIndex].percentage;
		var lower = colorStops[lowerIndex].percentage;				    		
						
		if (upper - lower > 0) {			
			weight = ((percentage - lower) / (upper - lower));
		}				
		
		//console.log(lower + '-' + upper + ' (' + percentage + ') ' + (weight*100) + '%');

    color = getStepColor(colorStops[lowerIndex].color, colorStops[upperIndex].color, weight);		
		
		jQuery(this).css({ "background-color": rgbToHex(color[0], color[1], color[2]) });
	});
}

function generate_bar_chart(element, retailerData, nationalData) {
	
	var ctx = element.getContext('2d');

	var retailerDataset = {
		label: 'Retailer',
		data: retailerData,
		backgroundColor: 'rgba(0,121,194,0.9)',
		borderColor: 'rgba(0,121,194,1.0)',
		borderWidth: 1,
		pointRadius: 0
	};
	
	
	var data = {		
		labels: JSON.parse(sfp.steps),
		datasets: [retailerDataset]
	};

	if (nationalData !== undefined && nationalData.length > 0) {
		var nationalDataset = {
			label: 'National Average',		
			data: nationalData,
			borderColor: 'rgba(33,52,71,0.2)',
			backgroundColor: 'rgba(220,220,220,0.8)',
			borderWidth: 1,
			borderDash: [3, 1],
			pointRadius: 0
		};
		
		data.datasets.push(nationalDataset);
	}

	
	
	

	var options = {
		layout: {
			padding: {
				left: 0,
				right: 5,
				top: 30,
				bottom: 30
			}
		},
		scales: {
        xAxes: [{
					 offset: true,
           gridLines: {
              offsetGridLines: true
           }
        }],
				yAxes: [{
						ticks: {
								beginAtZero: true,
								min: 0,
								max: 100
						}
				}]
    },
		legend: {
			position: 'bottom'
		},
	
		//responsive: true
		//, maintainAspectRatio: false
	};

	var myBarChart = new Chart(ctx, {
    type: 'bar',
    data: data,
    options: options
	});
}

function generate_chart(element, retailerData, nationalData) {
	var ctx = element.getContext('2d');

	var retailerDataset = {
		label: 'Retailer',
		data: retailerData,
		backgroundColor: 'rgba(0,121,194,0.2)',
		borderColor: 'rgba(0,121,194,0.7)',
		borderWidth: 3,
		pointRadius: 0
	};
	
	
	var data = {		
		labels: JSON.parse(sfp.steps),
		datasets: [retailerDataset]
	};

	if (nationalData !== undefined && nationalData.length > 0) {
		var nationalDataset = {
			label: 'National Average',		
			data: nationalData,
			borderColor: 'rgba(33,52,71,0.9)',
			borderWidth: 2,
			borderDash: [3, 1],
			pointRadius: 0
		};
		
		data.datasets.push(nationalDataset);
	}

	
	
	

	var options = {
		layout: {
			padding: {
				left: 0,
				right: 5,
				top: 30,
				bottom: 30
			}
		},
		scale: {
			ticks: {
				display: false,
				min: 0,
				max: 100,
				maxTicksLimit: 5
			},
			pointLabels: {
				fontSize: 17,
				fontStyle: 'bold',
				fontColor: '#888',
			},
			angleLines: {
				display: false
			},
			gridLines: {
				color: [
					'rgba(228,29,56,1)',
					'rgba(247,148,29,1)',
					'rgba(241,204,22,1)',
					'rgba(141,198,63,1)',
					'rgba(82,184,72,1)'
				],
				//lineWidth: 0.5
			}
		},
		legend: {
			position: 'right'
		},
	
		//responsive: true
		//, maintainAspectRatio: false
	};



	var myRadarChart = new Chart(ctx, {
		type: 'radar',
		data: data,
		options: options
	});

}

function create_charts() {
	
	jQuery(function () {
		jQuery('[data-toggle="tooltip"]').tooltip();
	});
	
	
	var charts = document.getElementsByClassName('radar-chart');	
	var periods = sfp['periods'];	
	
	for (var i = 0; i < charts.length; i++) {
		var chart = charts[i];
		var index = 0;
		if (chart.getAttribute('data-period')) {
			index = chart.getAttribute('data-period');
		}
		
		var retailerScores = periods[index].retailer;
		var nationalScores = periods[index].national;
		
		//generate_chart(chart, JSON.parse(retailerScores), JSON.parse(nationalScores));		
		generate_bar_chart(chart, JSON.parse(retailerScores), JSON.parse(nationalScores));		
	}
}







