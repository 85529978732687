/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	var slick_slider;
	
	var slick_settings = {
		infinite: false,
		draggable: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,		
		autoplay: false,
		prevArrow: '<div class="slick-prev icon icon-chevron-left"></div>',
		nextArrow: '<div class="slick-next icon icon-chevron-right"></div>',
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,						
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,						
				}
			}
		]
	};
	
	function handle_retailer_nested_tabs() {
		$("ul.nav-tabs a").click(function (e) {
			console.log('preventing default tab click');
			e.preventDefault();  
				$(this).tab('show');
		});
	}
	
	function preload_banner_image() {
		var $src = $('.page-banner').data('image-src');
		
		if ($src) {
			$img = new Image();
			
			$img.onload = function() {			
				$('.parallax-window').parallax({imageSrc: $src});
				$('.page-banner .spinner').fadeOut();
				$('.page-banner .banner-contents').css('opacity', '1');
			};
			
			$img.src = $src;						
		}
	}


	function latest_pagination() {
		$page = 1;
		$('.ajax-load-posts').on('click', function (event) {
			event.preventDefault();
			$page++;
			$.ajax({
				url: ajaxpagination.ajaxurl,
				type: 'post',
				data: {
					action: 'ajax_pagination',
					query_vars: ajaxpagination.query_vars,
					page: $page
				},
				beforeSend: function () {
					$('.ajax-load-posts').hide();
					//$('html, body').animate({scrollTop: $(document).height()-100}, 'slow');
					$('.loader-container .spinner').show();
				},
				success: function (result) {
					if (!result) {
						$('.loader-container').hide();
						$('.no-more-posts').slideDown();
					}
					else {
						$('.ajax-load-posts').show();
						$('.loader-container').hide();
						//$('.loader-container .spinner').hide();
						$('.posts-loop .row').append(result);
					}
				}
			});
		});
	}

	function press_pagination() {
		$page = 1;
		$('.ajax-load-posts').on('click', function (event) {
			event.preventDefault();
			$page++;
			$.ajax({
				url: ajaxpagination.ajaxurl,
				type: 'post',
				data: {
					action: 'ajax_pagination',
					query_vars: ajaxpagination.query_vars,
					page: $page
				},
				beforeSend: function () {
					$('.ajax-load-posts').hide();
					//$('html, body').animate({scrollTop: $(document).height()-100}, 'slow');
					$('.loader-container .spinner').show();
				},
				success: function (result) {
					if (!result) {
						$('.loader-container').hide();
						$('.no-more-posts').slideDown();
					}
					else {
						$('.ajax-load-posts').show();
						$('.loader-container').hide();
						//$('.loader-container .spinner').hide();
						$('.posts-loop').append(result);
					}
				}
			});
		});
	}

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
	var Sage = {
// All pages
		'common': {
			init: function () {
				//slick_slider = $('.latest-news-carousel').slick(slick_settings);
				slick_slider = $('.latest-news-carousel');
				slick_slider.slick(slick_settings);
				
				jQuery('#menu-top-menu li.search').find('a').attr('data-toggle', 'modal');

				$('#menu-primary-menu a.dropdown-toggle').on('click', function (e) {
					if ($('.navbar-toggler').is(':hidden')) {
						e.preventDefault();
						location.href = $(this).attr('href');						
					}
				});		
				
				preload_banner_image();

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
				$(window).scrollTop(0);
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'blog': {
			init: function () {
				latest_pagination();
			},
			finalize: function () {
			}
		},
		'category': {
			init: function () {
				latest_pagination();
			}
		},
		'author': {
			init: function () {
				latest_pagination();
			}
		},
		'press_releases': {
			init: function () {
				press_pagination();
			}
		},
		'single_retailer': {
			init: function () {
				create_charts();
				gradient_backgrounds();
				toggle_year_handlers();				
				//handle_retailer_nested_tabs();
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};
	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';
			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});
			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};
	// Window Events
	$(window).scroll(function () {
		if ($(document).scrollTop() > 50) {
			$('#navbar-primary').addClass('shrink');
			$('#navbar-secondary').fadeOut(600);
		}
		else {
			$('#navbar-primary').removeClass('shrink');
			$('#navbar-secondary').fadeIn(600);
		}
	});

	

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
